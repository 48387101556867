@import "./variables";
@import "./mixins";
@import "./colorCode";

%sub-text {
    font-size: 0.875rem;
}

%heavy-text {
    font-size: 1.5rem;
    font-weight: 500;
}
