.LoaderContainer {
    width: 100%;
}

.small-loader {
    border: 1px solid #f3f3f3;

    /* Light grey */
    border-top: 1px solid #3498db;

    /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin: auto;
    box-sizing: border-box;
}

.custom-loader {
    border: 4px solid #f3f3f3;

    /* Light grey */
    border-top: 4px solid #3498db;

    /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: auto;
    box-sizing: border-box;
}
