// BrandColors

$McKinseyDeepBlue: #051c2c;
$McKinseyElectricBlue: #2251ff;
$McKinseyCyan: #00a9f4;

// NeutralColors

$Superdarkgray: #333333;
$Darkgray: #4d4d4d;
$MidGray: #757575;
$Lightgray: #b3b3b3;
$Superlightgray: #d0d0d0;
$Palegray: #e6e6e6;
$Superpalegray: #f0f0f0;
$Black: #000000;
$White: #ffffff;

// FunctionalColors

$Bluetransition1: #034b6f;
$Bluetransition2: #027ab1;
$Bluetransition3: #39bdf3;
$Bluetransition4: #71d2f1;
$McKinseyPaleElectricBlue: #afc3ff;
$McKinseyTurquoise: #3c96b4;
$McKinseyPaleBlue: #aae6f0;
$McKinseyPurple: #8c5ac8;
$McKinseyPink: #e6a0c8;
$McKinseyRed: #e5546c;
$McKinseyOrange: #faa082;
$lightTabBorderColor: #dee2e6;
$lightBackgroundColor: #f5f6f6;

// Ag grid colors
$MasterRowArrowColor: #181d1f;
$MasterRowArrowColorOnHover: #2196f3;

// New Design colors
$BackgroundColorDark: #1b1e2a;
$TransparentBlackColor: #00000080;
$greyTextColor: #14172199;
$primaryTextColor: #141721e0; // rgba(20, 23, 33, 0.88)
$lightBorderColor: #1417211a; // rgba(20, 23, 33, 0.10)
$primaryTextColor60: #14172199; // rgba(20, 23, 33, 0.60)

$PrimaryBackgroundColor: #2b2e39;
$secondaryTextColor: #14172199;

// Job Status Colors
$JobStatusSuccessColor: #26bf56;
$JobStatusFailedColor: #f03b3a;
$JobStatusOngoingCancelledColor: #f17c02;
$AssessmentInReviewColor: #009eff;

// New Design colors
$borderColor: #f5f6f6;
