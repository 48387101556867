$fontFamily: theinhardt-pan;
$backgroundColor1: #f6f6f6;
$lightFontColor: #cccccc;
$darkFontColor: #666666;
$primaryButtonColor: #051c2c;
$secondaryColor: rgb(0 188 249 / 100%);
$defaultFontSize: 1rem;
$mdFont: 1.2rem;
$searchBoxMaxWidth: 40.625rem;
$subFont: 0.875rem;
$heavyFont: 1.5rem;
$xlFont: 2rem;
$chartSubTitleFont: 1.4rem;
$defaultFontWeight: 400;
$subFontWeight: 300;
$heavyFontWeight: 500;
$minWidth: 4.375rem;
$searchBoxPadding: 0.625rem;
$tooltipBackgroundColor: rgb(247 247 247 / 95%);
$tooltipTextColor: rgb(51 51 51) !important;
$buttonHoverBgColor: #d3d4d5;
