/* Webfont: Theinhardt Pan-Light
Optimo webfonts are protected by copyright law and provided under license. To modify, alter, translate, convert,
decode or reverse engineer in any manner whatsoever, including converting the Font Software into a different
format is strictly prohibited. The webfont files are not to be used for anything other than web font use.
*/

@font-face {
    font-family: theinhardt-pan;
    src: url("TheinhardtPan-Light.woff");
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
}
