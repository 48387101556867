@import "./variables";
@import "./colorCode";

@mixin ag-grid-overflow() {
    .ag-root-wrapper,
    .ag-root,
    .ag-body-viewport,
    .ag-center-cols-container,
    .ag-center-cols-viewport,
    .ag-center-cols-clipper,
    .ag-body-clipper.ag-layout-auto-height {
        overflow: visible !important;
    }
}

@mixin tooltip-style($dark_theme: false, $width: 200px) {
    min-width: $width;

    @if $dark_theme {
        background-color: $BackgroundColorDark;
        min-width: $width;
        font-size: 0.825rem;
        color: $White !important;
        padding: 0.625rem 0.85rem;
        /* stylelint-disable-next-line block-closing-brace-newline-after */
    } @else {
        border-radius: 0 !important;
        background-color: $tooltipBackgroundColor;
        color: $tooltipTextColor;
        font-size: 0.75rem;
    }
}

@mixin elliptical-text() {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
